<h1 mat-dialog-title>Filter</h1>
<div id="myForm" mat-dialog-content>
  <!-- form id="myForm" [formGroup]="inputForm" -->

  <mat-form-field appearance="fill">
    <mat-label class="matLabel">Fishing Spot Filter: </mat-label>
    <input matInput type="text" [(ngModel)]="searchForm.nameSearch" placeholder="Enter Fishing Spot Name" />
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label class="matLabel">Distance (Min): </mat-label>
    <input matInput type="number" [(ngModel)]="searchForm.minDistance" placeholder="Minimum" />

  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label class="matLabel">Distance (Max): </mat-label>
    <input matInput type="number" [(ngModel)]="searchForm.maxDistance" placeholder="Maximum" />
  </mat-form-field>

  <button title="Refresh From Server" mat-icon-button aria-label="Refresh From Server" (click)="onRefreshFromServer()">
    <mat-icon>autorenew</mat-icon>Refresh From Server
  </button>
  <button title="Download GPX" mat-button aria-label="Download GPX" (click)="onGpxDownload()">Download GPX
  </button>

  <!-- /form -->

</div>
