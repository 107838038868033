<!-- mat-toolbar color="primary" class="example-toolbar" -->
<div  id="nav">
  <button mat-icon-button (click)="snav.toggle()">
    <mat-icon>menu</mat-icon>
  </button>
  <h1 class="example-app-name">TarponWeb</h1>
  <form [formGroup]="inputForm">
    <mat-form-field appearance="fill">
      <mat-label class="matLabel">Fishing Spot Filter: </mat-label>
      <input matInput type="text" placeholder="Enter Fishing Spot Name" [value]="nameSearch" formControlName="nameSearch" />
    </mat-form-field>
  </form>
</div>
<!-- /mat-toolbar -->

<mat-sidenav-container class="example-container">
  <mat-sidenav #snav mode="over">
    <div id="header">
      <button mat-button (click)="openFishingFilterDialog()">Open dialog</button>
      <button *ngIf="deferredPrompt" title="Install PWA" mat-button aria-label="Install PWA"
        (click)="onInstallPWA()">Install PWA
      </button>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="flex-container" fxLayout="row" fxLayout.xs="column">
      <div fxFlex="30%">
        <cdk-virtual-scroll-viewport *ngIf="fishingSpotsLoaded" [itemSize]="21" class="viewport"
          (scrolledIndexChange)="scrolledIndexChange($event)">
          <div *cdkVirtualFor="let fishingSpot of fishingSpots | fishingspotfilter: nameSearch;
                       let index = index;
                       let first = first;
                       let last = last;
                       let even = even;
                       let odd = odd;" [class.alternate]="odd">
            <span class="item-detail" (click)="openInfoWindowFromScroll(fishingSpot)">
              {{index + 1}}: {{fishingSpot.name}} ({{fishingSpot.distance.toFixed(1)}}m) </span>
          </div>
        </cdk-virtual-scroll-viewport>
      </div>
      <div fxFlex="70%">
        <div id="fishingMap"></div>
      </div>
    </div>

  </mat-sidenav-content>
</mat-sidenav-container>


<!--

<div id="header">
  <div class="content">
    <form [formGroup]="inputForm">
      <mat-label class='matLabel'>Fishing Spot Filter: </mat-label>
      <input matInput type="text" placeholder="Enter Fishing Spot Name" formControlName="nameSearch" />
      <mat-label class='matLabel'>Distance (Min): </mat-label>
      <input matInput type="text" [(ngModel)]="minDistance" [ngModelOptions]="{standalone: true}"
        placeholder="Minimum" />
      <mat-label class='matLabel'>Distance (Max): </mat-label>
      <input matInput type="text" [(ngModel)]="maxDistance" [ngModelOptions]="{standalone: true}"
        placeholder="Maximum" />
      <button title="Refresh From Server" mat-icon-button aria-label="Refresh From Server"
        (click)="refreshFromServer()">
        <mat-icon>autorenew</mat-icon>
      </button>
      <button title="Download GPX" mat-button aria-label="Download GPX" (click)="onGpxDownload()">Download GPX
      </button>
      <button *ngIf="deferredPrompt" title="Install PWA" mat-button aria-label="Install PWA"
        (click)="onInstallPWA()">Install PWA
      </button>
    </form>
  </div>
  <div>
    <mat-label class="matLabel">Filter From Server
      ( name: {{this.nameSearchLast}} ,
      Min Dist: {{this.minDistanceLast}} ,
      Max Dist: {{this.maxDistanceLast}} ,
      Number Of Spots: {{this.fishingSpots.length}}) </mat-label>
  </div>
</div>

 -->


<!--
<div class="example-action-buttons">
  <button mat-button (click)="accordion.openAll()">Expand All</button>
  <button mat-button (click)="accordion.closeAll()">Collapse All</button>
</div>

<mat-accordion class="example-headers-align" multi>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Fishing Map
      </mat-panel-title>
      <mat-panel-description>
        Type your name and age
        <mat-icon>account_circle</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div id="fishingMap222"></div>

  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Fishing Entries
      </mat-panel-title>
      <mat-panel-description>
        Inform the date you wish to travel
        <mat-icon>date_range</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <cdk-virtual-scroll-viewport *ngIf="fishingSpotsLoaded" [itemSize]="20" class="viewport"
      (scrolledIndexChange)="scrolledIndexChange($event)">
      <div *cdkVirtualFor="let fishingSpot of fishingSpots | fishingspotfilter: nameSearch;
                       let index = index;
                       let first = first;
                       let last = last;
                       let even = even;
                       let odd = odd;" [class.alternate]="odd">
        <span class="item-detail" (click)="openInfoWindowFromScroll(fishingSpot)">
          {{index + 1}}: {{fishingSpot.name}} ({{fishingSpot.distance.toFixed(1)}}m) </span>
      </div>
    </cdk-virtual-scroll-viewport>

  </mat-expansion-panel>
</mat-accordion>



<button mat-button (click)="sidenavToggle()">
  <mat-icon>menu</mat-icon>
</button>
<button mat-button [matMenuTriggerFor]="menu">
  Menu
</button>

<mat-menu #menu="matMenu">
  <button mat-menu-item>Item 2</button>
</mat-menu>


<mat-sidenav-container class="example-container">
  <mat-sidenav mode="push" [(opened)]="opened" position="end">
    <div class="ex1">

      <div>
        <form [formGroup]="inputForm">
          <input matInput type="text" placeholder="Search" formControlName="nameSearch" />

          <input matInput type="text" [(ngModel)]="minDistance" [ngModelOptions]="{standalone: true}"
            placeholder="Minimum" />
          <input matInput type="text" [(ngModel)]="maxDistance" [ngModelOptions]="{standalone: true}"
            placeholder="Maximum" />
          <button title="Refresh From Server" mat-icon-button aria-label="Refresh From Server"
            (click)="refreshFromServer()">
            <mat-icon>autorenew</mat-icon>
          </button>

        </form>
      </div>

      <cdk-virtual-scroll-viewport *ngIf="fishingSpotsLoaded" [itemSize]="20" class="viewport"
        (scrolledIndexChange)="scrolledIndexChange($event)">
        <div *cdkVirtualFor="let fishingSpot of fishingSpots | fishingspotfilter: nameSearch;
                       let index = index;
                       let first = first;
                       let last = last;
                       let even = even;
                       let odd = odd;" [class.alternate]="odd">
          <span class="item-detail" (click)="openInfoWindowFromScroll(fishingSpot)">
            {{index + 1}}: {{fishingSpot.name}} ({{fishingSpot.distance.toFixed(1)}}m) </span>
        </div>
      </cdk-virtual-scroll-viewport>

    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div>
      <div id="fishingMap2"></div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>


 -->

<!--

<div id="container">
  <div id='first'>

    <cdk-virtual-scroll-viewport *ngIf="fishingSpotsLoaded" [itemSize]="20" class="viewport"
      (scrolledIndexChange)="scrolledIndexChange($event)">
      <div *cdkVirtualFor="let fishingSpot of fishingSpots | fishingspotfilter: nameSearch;
                       let index = index;
                       let first = first;
                       let last = last;
                       let even = even;
                       let odd = odd;" [class.alternate]="odd">
        <span class="item-detail" (click)="openInfoWindowFromScroll(fishingSpot)">
          {{index + 1}}: {{fishingSpot.name}} ({{fishingSpot.distance.toFixed(1)}}m) </span>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>

  <div id='second'>
    <div id='fishingMap'>

    </div>
  </div>
  <div id="clear"></div>
</div>
 -->
