
  <div id="filterOverlay">
    <div class="content">
        lm test
      <form [formGroup]="inputForm">
        <!-- div>
          <mat-label class='matLabel'>Fishing Spot Filter: </mat-label>
          <input matInput type="text" placeholder="Enter Fishing Spot Name" formControlName="nameSearch" />
        </div>

        <div>
          <mat-label class='matLabel'>Distance (Min): </mat-label>
          <input matInput type="text" [(ngModel)]="minDistance" [ngModelOptions]="{standalone: true}"
            placeholder="Minimum" />
        </div>

        <div>
          <mat-label class='matLabel'>Distance (Max): </mat-label>
          <input matInput type="text" [(ngModel)]="maxDistance" [ngModelOptions]="{standalone: true}"
            placeholder="Maximum" />
        </div -->

        <!-- 
          <button title="Refresh From Server" mat-icon-button aria-label="Refresh From Server"
            (click)="refreshFromServer()">
            <mat-icon>autorenew</mat-icon>
          </button>
          <button title="Download GPX" mat-button aria-label="Download GPX" (click)="onGpxDownload()">Download GPX
          </button>
          -->

      </form>
    </div>
    <!-- div>
        <mat-label class="matLabel">Filter From Server
          ( name: {{this.nameSearchLast}} ,
          Min Dist: {{this.minDistanceLast}} ,
          Max Dist: {{this.maxDistanceLast}} ,
          Number Of Spots: {{this.fishingSpots.length}}) </mat-label>
      </div -->

  </div>
