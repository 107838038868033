import { Pipe, PipeTransform } from '@angular/core';
import { FishingSpotInterface } from './interface/fishing-spot-interface';

@Pipe({
  name: 'fishingspotfilter'
})
export class FishingspotfilterPipe implements PipeTransform {

  transform(fishingSpots: FishingSpotInterface[],
            nameSearch: string): FishingSpotInterface[] {

    console.log('in FishingspotfilterPipe() ... nameSearch:', nameSearch);
    if (nameSearch.length === 0) {
      return fishingSpots;
    } else {
      return fishingSpots.filter(fishingSpot =>
        fishingSpot.name.toLowerCase().includes(nameSearch.toLowerCase()));
    }
  }

}
