import { Component, ViewChild, OnInit, NgZone } from '@angular/core';

import { FishingService } from '../../service/fishing.service';
import { UserCoordsInterface } from '../../interface/user-coords-interface';
import { FishingSpotInterface } from '../../interface/fishing-spot-interface';
import { FishingspotfilterPipe } from '../../fishingspotfilter.pipe';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MapInfoWindow, MapMarker, GoogleMap } from '@angular/google-maps';

// import { Subject } from 'rxjs';
// import { throttleTime, switchMap, tap } from 'rxjs/operators';

// import { Observable, of } from 'rxjs';
// import { catchError, map } from 'rxjs/operators';

import { ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
// import { MatAccordion } from '@angular/material/expansion';
// import { FlexLayoutModule } from '@angular/flex-layout';

import { MatDialog } from '@angular/material/dialog';
// import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FishingFilterDialogComponent } from '../fishing-filter-dialog/fishing-filter-dialog.component';

@Component({
  selector: 'app-fishing-home',
  templateUrl: './fishing-home.component.html',
  styleUrls: ['./fishing-home.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FishingHomeComponent implements OnInit {
  // @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild(CdkVirtualScrollViewport)
  viewport: CdkVirtualScrollViewport;

  isOpen = false;
  opened: boolean = false;

  public inputForm: FormGroup;

  public nameSearch = '';
  public minDistance: number;
  public maxDistance: number;
  public nameSearchLast = '';
  public minDistanceLast: number;
  public maxDistanceLast: number;
  private fishingMap = null;
  private userEmail = null;
  private userLocationCoords: UserCoordsInterface = null;
  public fishingSpotsLoaded = false;
  public fishingSpots = [];
  public homeMarker = null;
  public markers = [];
  public mapOptions: google.maps.MapOptions = null;
  public bounds = null;

  public range = { start: 0, end: 0 };
  public lastRange = null;

  public image =
    'https://developers.google.com/maps/documentation' +
    '/javascript/examples/full/images/beachflag.png';

  public image2 = 'http://maps.google.com/mapfiles/ms/icons/marina.png';
  // public image3 = 'http://maps.google.com/mapfiles/ms/icons/pink-pushpin.png';
  public image3 = 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png';

  public icon1 = {
    url: 'http://maps.google.com/mapfiles/ms/icons/rangerstation.png', // url
    scaledSize: new google.maps.Size(50, 50), // scaled size
    origin: new google.maps.Point(0, 0), // origin
    anchor: new google.maps.Point(0, 0), // anchor
    labelOrigin: new google.maps.Point(25, 60)
  };


  public infoContent = null;
  public infoWindow = new google.maps.InfoWindow();

  // Initialize deferredPrompt for use later to show browser install prompt.
  public deferredPrompt: any;

  constructor(private fishingService: FishingService, public dialog: MatDialog) {
    console.log('in FishingHomeComponent.constructor() ...');
  }

  async ngOnInit() {
    this.inputForm = new FormGroup({
      nameSearch: new FormControl(),
      distanceSearch: new FormControl()
    });
    this.setupNameSearchInputChange();
    this.userEmail = localStorage.getItem('userEmail');
    this.userEmail = 'lmorin@tarponweb.com';
    console.log('in FishingHomeComponent.ngOnInit() ... userEmail: ', this.userEmail);
    if (this.userEmail === null) {
      this.userEmail = 'GlobalDefault';
    }
    await this.getFishingSpots(this.userEmail);

    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      this.showInstallPromotion();
      // Optionally, send analytics event that PWA install promo was shown.
      console.log(` ********* 'beforeinstallprompt' event was fired.`);
    });




    function myFunction(x) {
      console.log('media Query Event ... in myFunction() : ', x);
      console.log('media Query Event ... media : ', x.media);

      if (x.matches) { // If media query matches
        document.body.style.backgroundColor = "yellow";
      } else {
        document.body.style.backgroundColor = "pink";
      }
    }

    var x = window.matchMedia("(max-width: 700px)")
    myFunction(x) // Call listener function at run time
    x.addListener(myFunction) // Attach listener function on state changes

    /* Extra small devices (phones, 600px and down) */
    // @media only screen and(max - width: 600px) {... }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    // @media only screen and(min - width: 600px) {... }

    /* Medium devices (landscape tablets, 768px and up) */
    // @media only screen and(min - width: 768px) {... }

    /* Large devices (laptops/desktops, 992px and up) */
    // @media only screen and(min - width: 992px) {... }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    // @media only screen and(min - width: 1200px) {... }

  }


  openFishingFilterDialog() {
    const dialogRef = this.dialog.open(FishingFilterDialogComponent, {
      data: {
        nameSearch: this.nameSearch,
        minDistance: this.minDistance,
        maxDistance: this.maxDistance
      }
    });

    dialogRef.afterClosed().subscribe(data => {

      console.log('afterClosed() searchForm:', data.searchForm);
      this.nameSearch = data.searchForm.nameSearch;
      this.minDistance = data.searchForm.minDistance;
      this.maxDistance = data.searchForm.maxDistance;
      this.refreshFromServer();

      /*
      if(result.event == 'Add'){
        this.addRowData(result.data);
      }else if(result.event == 'Update'){
        this.updateRowData(result.data);
      }else if(result.event == 'Delete'){
        this.deleteRowData(result.data);
      }
      */

    });
  }



  sidenavToggle() {
    console.log('sidenavToggle() ... ******************');
    this.opened = !this.opened;
  }

  showInstallPromotion() {
    console.log('showInstallPromotion() ... ******************');
  }

  async onInstallPWA() {
    // Hide the app provided install promotion
    this.hideInstallPromotion();
    // Show the install prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    const { outcome } = await this.deferredPrompt.userChoice;
    // Optionally, send analytics event with outcome of user choice
    console.log(`User response to the install prompt: ${outcome}`);
    // We've used the prompt, and can't use it again, throw it away
    this.deferredPrompt = null;
  };


  hideInstallPromotion() {
    console.log('hideInstallPromotion() ... ******************');
  }

  refreshFromServer() {
    console.log('refreshFromServer() ... ');

    this.userLocationCoords.minDistance = this.minDistance;
    this.userLocationCoords.maxDistance = this.maxDistance;
    this.userLocationCoords.lat = this.homeMarker.position.lat();
    this.userLocationCoords.lng = this.homeMarker.position.lng();

    const fishingSpots$ = this.fishingService.getFishingSpots(this.nameSearch, this.userLocationCoords);
    fishingSpots$.subscribe(fishingSpots => {
      this.fishingSpots = fishingSpots;
      this.scrolledIndexChange(1);
      this.viewport.scrollToIndex(0, 'smooth');
      this.nameSearchLast = this.nameSearch;
      this.minDistanceLast = this.minDistance;
      this.maxDistanceLast = this.maxDistance;
    });
  }

  setupNameSearchInputChange() {
    const nameSearchControl = this.inputForm.get('nameSearch');
    nameSearchControl.valueChanges.forEach(
      (value: string) => {
        console.log('Name Input Change() ... value:', value);
        this.nameSearch = value;
        this.scrolledIndexChange(1);
      }
    );
  }

  scrolledIndexChange(event): void {
    console.log('scrolledIndexChange event: ', event);
    const range = {
      start: 0,
      end: 0
    };

    let start = event;
    if (start < 1) {
      range.start = 1;
      range.end = 32;
    } else {
      range.start = start;
      range.end = start + 33;
    };
    console.log('***');
    console.log('scrolledIndexChange range: ', range);
    this.removeMarkers();
    this.addMarkers(range);
  }

  setupMarkers(range): void {
    console.log('setupMarkers range: ', range);

    this.removeMarkers();
    if (this.fishingSpots.length > 0) {
      this.addMarkers(range);
    }
  }


  getFishingSpots(userEmail: string) {
    const userCoords$ = this.fishingService.getFishingUserCoords(userEmail);

    userCoords$.subscribe(userCoords => {

      this.setupMapAndHomeMarker(userCoords);

      const fishingSpots$ = this.fishingService.getFishingSpots(this.nameSearch, userCoords);
      fishingSpots$.subscribe(fishingSpots => {
        this.fishingSpots = fishingSpots;
        console.log('in component fishingSpots:', fishingSpots);
        this.fishingSpotsLoaded = true;
        this.nameSearchLast = this.nameSearch;
        this.minDistanceLast = this.minDistance;
        this.maxDistanceLast = this.maxDistance;
      });
    });
  }

  ngAfterViewInit(): void {

  }


  setupMapAndHomeMarker(userCoords) {
    this.userLocationCoords = userCoords;
    this.minDistance = userCoords.minDistance;
    this.maxDistance = userCoords.maxDistance;

    const image =
      'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png';

    this.fishingMap = new google.maps.Map(
      document.getElementById('fishingMap') as HTMLElement,
      {
        zoom: 14,
        center: { lat: this.userLocationCoords.lat, lng: this.userLocationCoords.lng },
      }
    );

    this.homeMarker = new google.maps.Marker({
      position: {
        lat: this.userLocationCoords.lat,
        lng: this.userLocationCoords.lng,
      },
      label: 'Home',
      title: 'Home Marker title ',
      icon: image,
      draggable: true,
      map: this.fishingMap,
    });

    this.homeMarker.addListener('click', () => {
      this.openHomeInfoWindow(this.homeMarker);
    });

    google.maps.event.addListener(this.homeMarker, 'dragend', (event) => {
      console.log('map this.homeMarker clicked() ... event', event);
      this.setupNewHomeLocation(event);
    });

    google.maps.event.addListener(this.fishingMap, 'click', (event) => {
      console.log('map clicked() ... event', event);
      this.mapClicked(event);
    });
  }

  setupNewHomeLocation(newLoc) {
    var newLocStr = newLoc.latLng.lat().toFixed(4) + ', '
      + newLoc.latLng.lng().toFixed(4);
    console.log('in component fishingSpots: move home marker:', newLocStr);

    let d = this.getDistanceInMiles(newLoc.latLng, this.markers[0].getPosition());
    console.log('in component fishingSpots: distance:', d.toFixed(1));

    for (let i = 0; i < this.fishingSpots.length; i++) {
      let myLatLng = new google.maps.LatLng(
        parseFloat(this.fishingSpots[i].latDD),
        parseFloat(this.fishingSpots[i].lngDD));
      this.fishingSpots[i].distance = this.getDistanceInMiles(
        newLoc.latLng, myLatLng);
    }
  }

  removeMarkers() {
    console.log('removeMarkers() ... ');
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(null);
      this.markers[i] = null;
    }

    this.markers = [];
    console.log('removeMarkers() EXIT ... markers = ', this.markers);
  }

  addMarkers(range) {
    console.log('addMarkers() ... range:', range);
    const bounds = new google.maps.LatLngBounds();
    bounds.extend(this.homeMarker.position);

    const fishingspotfilterPipe = new FishingspotfilterPipe();
    const fiteredArr = fishingspotfilterPipe.transform(this.fishingSpots,
      this.nameSearch);

    for (let i = range.start; i <= range.end && i <= fiteredArr.length; i++) {
      const fishingSpot: FishingSpotInterface = fiteredArr[i - 1];
      const marker: any = this.createMarker(fishingSpot, i);
      // console.log('addMarkers() ... marker:', marker);
      this.markers.push(marker);
      bounds.extend(marker.position);
    }
    this.fishingMap.fitBounds(bounds);
  }

  createMarker(fishingSpot: FishingSpotInterface, index) {
    const marker: any = new google.maps.Marker({
      position: new google.maps.LatLng(
        parseFloat(fishingSpot.latDD),
        parseFloat(fishingSpot.lngDD)),
      title: index + ':' + fishingSpot.name + '(' + fishingSpot.distance.toFixed(1) + ' m)',
      label: {
        color: 'black',
        text: '' + (index),
      },
      map: this.fishingMap,
    });

    marker.addListener('click', () => {
      // this.markerClick = true;

      this.openInfoWindow(marker.fishingSpot, marker);
      // let myIndex = Number(marker.getLabel().text) - 1;
      // let myIndex2 = myIndex - 15;
      // this.viewport.scrollToIndex(myIndex2, 'smooth');


      /*
      this.viewport.renderedRangeStream.subscribe(range => {
        console.log('LMTEST222 range', range);
        //console.log('LMTEST range2', this.viewport.getRenderedRange());
        this.setupMarkers(this.viewport.getRenderedRange());
      });
      */


      // this.markerClick = false;

    });

    fishingSpot.marker = marker;
    marker.fishingSpot = fishingSpot;
    return marker;
  }

  mapClicked(event: Event) {
    console.log('mapClicked event: ', event);
  }

  openHomeInfoWindow(marker: google.maps.Marker): any {
    this.infoWindow.close();
    this.infoWindow.setContent('Home Info');
    this.infoWindow.open(marker.getMap(), marker);
  }

  openInfoWindowFromScroll(fishingSpot: FishingSpotInterface): any {
    console.log('openInfoWindowFromScroll() ...');
    this.infoContent = 'marker info 111';
    const marker = fishingSpot.marker;
    this.openInfoWindow(fishingSpot, marker);
  }

  openInfoWindow(fishingSpot: FishingSpotInterface, marker: google.maps.Marker) {
    const description = fishingSpot.material;
    const query = 'Florida Fishing ' + fishingSpot.name;

    const contentString =
      '<div id=\'content\'>' +
      '<div id=\'siteNotice\'> Site Information (' + marker.getLabel().text + ')' +
      '</div>' +
      '<h5 id=\'firstHeading\' class=\'firstHeading\'>' +
      fishingSpot.name + ' (' + fishingSpot.distance.toFixed(1) + 'm)' +
      '</h5>' +
      '<div id=\'bodyContent\'>' +
      ' <table>' +
      '  <tbody>' +
      '   <tr>' +
      '     <td class=\'text-left\'><b>Fishing Spot Id</b> : </td>' +
      '     <td class=\'text-left\'>' +
      fishingSpot.fishingSpotId +
      '</td>' +
      '   </tr>' +
      '   <tr>' +
      '     <td class=\'text-left\'><b>Distance</b> : </td>' +
      '     <td class=\'text-left\'>' +
      fishingSpot.distance.toFixed(2) +
      ' miles' +
      '     </td>' +
      '   </tr>' +
      '   <tr>' +
      '     <td class=\'text-left\'><b>Location</b> : </td>' +
      '     <td class=\'text-left\'>' +
      fishingSpot.latDM +
      ' / ' +
      fishingSpot.lngDM +
      '</td>' +
      '   </tr>' +
      '   <tr>' +
      '     <td class=\'text-left\'><b>Jurisdiction</b> : </td>' +
      '     <td class=\'text-left\'>' +
      fishingSpot.jurisdiction +
      '</td>' +
      '   </tr>' +
      '   <tr>' +
      '     <td class=\'text-left\'><b>County</b> : </td>' +
      '     <td class=\'text-left\'>' +
      fishingSpot.county +
      '</td>' +
      '   </tr>' +
      '   <tr>' +
      '     <td class=\'text-left\'><b>Description</b> : </td>' +
      '     <td class=\'text-left\'>' +
      description +
      '</td>' +
      '   </tr>' +
      '   <tr>' +
      '     <td class=\'text-left\'><b>More Info</b> : </td>' +
      '     <td class=\'text-left\'>' +
      '       <a href=\'https://www.google.com/search?q=' +
      query +
      '&rlz=1C5CHFA_enUS733US737' +
      '&oq=' +
      query +
      '&aqs=chrome..69 i57j69i58' +
      '&sourceid=chrome' +
      '&ie=UTF-8\' ' +
      ' target=\'_blank\'>' +
      fishingSpot.name +
      '</a>' +
      '     </td>' +
      '   </tr>' +
      ' </tbody>' +
      ' </table>' +
      '</div>' +
      '</div>';

    this.infoWindow.close();
    this.infoWindow.setContent(contentString);
    this.infoWindow.open(marker.getMap(), marker);
  }

  getDistanceInMiles(p1: google.maps.LatLng, p2: google.maps.LatLng): number {
    return (google.maps.geometry.spherical.computeDistanceBetween(p1, p2) / 1000 / 1.61);
  }


  onGpxDownload() {
    console.log('in FishingHomeComponent.onGpxDownload() ');

    let gpxStr = '<?xml version="1.0" encoding = "UTF-8" ?>' +
      '<gpx version="1.0" >' +
      '<name>Share Your Numbers</name>';

    for (const fishingSpot of this.fishingSpots) {
      gpxStr = gpxStr + '<wpt lat = "' +
        fishingSpot.latDD +
        '" lon = "' +
        fishingSpot.lngDD +
        '" >';
      gpxStr = gpxStr + '<name>' + fishingSpot.name + '</name>';
      gpxStr = gpxStr + '<desc>' + fishingSpot.material + '</desc>';
      gpxStr = gpxStr + '</wpt>';
    }

    gpxStr = gpxStr + '</gpx>';

    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(gpxStr));
    element.setAttribute('download', 'twsShare.gpx');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
}
