// import { Component, OnInit } from '@angular/core';
import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

// import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// export interface DialogData {
//  animal: 'panda' | 'unicorn' | 'lion';
// }

@Component({
  selector: 'fishing-filter-dialog',
  templateUrl: './fishing-filter-dialog.component.html',
  styleUrls: ['./fishing-filter-dialog.component.css']
})
export class FishingFilterDialogComponent implements OnInit {

  public searchForm = {
    nameSearch: '',
    minDistance: 0,
    maxDistance: 0,
  }

  // constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) { }
  constructor(@Inject(MAT_DIALOG_DATA) public inSearchForm: any,
    private dialogRef: MatDialogRef<FishingFilterDialogComponent>) {

      console.log('inSearchForm:', inSearchForm);
      this.searchForm = inSearchForm;
  }

  ngOnInit(): void {
  }

  onRefreshFromServer() {
    console.log('onRefreshFromServer() ...');
    //this.nameSearch2.emit(this.nameSearch);
    this.dialogRef.close({ searchForm: this.searchForm })
  }

  onGpxDownload() {

  }
}
