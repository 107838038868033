import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { HttpsClient } from '@angular/common/https';
import { ResultInterface } from '../interface/result-interface';
import { UserCoordsInterface } from '../interface/user-coords-interface';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FishingService {

  // private prefix = 'https://localhost:3000';
  private prefix = '';
  private fromCoordsUrl = this.prefix + '/api/fishingspot/fromcoordinates';
  private fishingSpotUrl = this.prefix + '/api/fishingspot';

  constructor(private http: HttpClient) { }

  getFishingUserCoords(inUserEmail: string): Observable<UserCoordsInterface> {
    console.log('in FishingService.getFishingUserCoords() ...');

    const http$ = this.http.get<ResultInterface>(this.fromCoordsUrl, {
      params: {
        userEmail: inUserEmail
      }
    });

    const userCoordsInterface$ = http$
      .pipe(
        tap(result => {
          console.log('FishingService.getFishingUserCoords() fetched result', result);
        }),
        map(result => result.data), // maps to UserCoords object
        catchError(this.handleError<ResultInterface>('getFishingSpots'))
      );

    console.log('in FishingService.getFishingUserCoords() ... userCoordsInterface$', userCoordsInterface$);
    return userCoordsInterface$;
  }

  getFishingSpots(nameSearch, userCoordsInterface: UserCoordsInterface): Observable<[]> {
    console.log('in FishingService.getFishingSpots() ...');

    const http$ = this.http.get<ResultInterface>(this.fishingSpotUrl, {
      params: {
        nameSearch: nameSearch,
        lat: userCoordsInterface.lat,
        lng: userCoordsInterface.lng,
        minDistance: userCoordsInterface.minDistance,
        maxDistance: userCoordsInterface.maxDistance,
      }
    });

    const fishingSpots$ = http$
      .pipe(
        tap(result => {
          console.log('FishingService.getFishingSpots() ... fetched result', result);
        }),
        map(result => result.data), // maps to fishingSpots array
        catchError(this.handleError<ResultInterface>('getFishingSpots'))
      );

    console.log('in FishingService.getFishingSpots() ...fishingSpots$', fishingSpots$);
    return fishingSpots$;
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  // tslint:disable-next-line: typedef
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
