import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//import { FormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from './material/material.module';

import { FishingHomeComponent } from './component/fishing-home/fishing-home.component';
import { TestMapComponent } from './component/test-map/test-map.component';
import { FishingspotfilterPipe } from './fishingspotfilter.pipe';
import { ServiceWorkerModule } from '@angular/service-worker';

// import { environment } from '../environments/environment';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FishingFilterComponent } from './component/fishing-filter/fishing-filter.component';
import { FishingFilterDialogComponent } from './component/fishing-filter-dialog/fishing-filter-dialog.component';

//const COMPONENTS = [
// FishingHomeComponent,
//  GoogleMapsModule
//]

@NgModule({
  declarations: [
    FishingHomeComponent,
    TestMapComponent,
    FishingspotfilterPipe,
    FishingFilterComponent,
    FishingFilterDialogComponent
  ],
  imports: [
    CommonModule,
    GoogleMapsModule,
    HttpClientModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
     ServiceWorkerModule.register('ngsw-worker.js', {
      // enabled: environment.production,
      enabled: true,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:8443'
    })
  ],
  exports: [
    FishingHomeComponent,
    MaterialModule,
    TestMapComponent,
  ]
})
export class TwsFishingAppModule { }
