import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'fishing-filter',
  templateUrl: './fishing-filter.component.html',
  styleUrls: ['./fishing-filter.component.css']
})
export class FishingFilterComponent implements OnInit {
  @Input() trigger: any;
  @Input() isOpen: boolean;
  @Input() nameSearch: string;
  @Input() minDistance: number;
  @Input() maxDistance: number;


  public inputForm: FormGroup;

  // public nameSearch = '';

  constructor() { }

  ngOnInit(): void {
  }

}
